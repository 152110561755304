import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import Grid from 'styled-components-grid';

import { withCssUnit } from '../utils/index';

const StyledRow = styled(({ gutterWidth, ...props }) => {
  return <Grid {...props}>{props.children}</Grid>;
})`
  display: flex;
  flex-wrap: wrap;
  margin-right: ${({ theme, gutterWidth }) => {
    const gw = !_.isNil(gutterWidth)
      ? gutterWidth
      : _.get(theme, 'gutterWidth');
    if (gw) {
      return '-' + withCssUnit(Math.floor(gw / 2));
    }
    return 0;
  }};
  margin-left: ${({ theme, gutterWidth }) => {
    const gw = !_.isNil(gutterWidth)
      ? gutterWidth
      : _.get(theme, 'gutterWidth');
    if (gw) {
      return '-' + withCssUnit(Math.floor(gw / 2));
    }
    return 0;
  }};
`;

const Row = ({ className, children, gutterWidth }) => {
  return (
    <StyledRow className={className} gutterWidth={gutterWidth}>
      {children}
    </StyledRow>
  );
};

export default Row;
